/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@layer components {

  .btn-confirm {
    @apply text-white bg-green-700 rounded px-10 py-3 font-heading disabled:opacity-50 disabled:bg-gray-400;
  }

  .btn {
    @apply text-gray-500 bg-white rounded border px-6 py-2 font-heading;
  }

  .btn-deny {
    @apply text-black bg-white border rounded px-12 py-3 font-heading;
  }

  /**
    Defines the height and width for 'main' buttons, such as the ones used for navigation, article page, etc.
   */
  .height-main-btn {
    @apply h-20
  }

  .size-main-btn {
    @apply w-72 height-main-btn;
  }

  /*
  * General tiles classes
   */
  .tile-wrapper {
    @apply cursor-pointer border rounded-tb text-center h-full;
  }

}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  opacity: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tile-selected {
  background-color: #d5d5d5;
}

html {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
